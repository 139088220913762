<template>
  <div>
    <topHeader :haveBanner="false" />
    <section>
      <div class="authentication">
        <div class="container">
          <p class="authentication__caption text-center">Welcome Back.</p>
          <div class="__spacet2p"></div>
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-2"></div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-8">
              <div class="__formwidth">
                <form @submit.prevent="handleUserLogin">
                  <div class="form-group">
                    <label for="" class="form__authformlabel"
                      >Email Adress or Phone Number</label
                    >
                      <input
                        type="text"
                        class="form-control form__authformedit"
                        placeholder="Hireme@example.gmail.com"
                        name="email"
                        v-model.trim="email"
                        :class="{ 'has-error': v$.email.$errors.length }"
                      />
                      <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                        <div class="error-msg invalid-feedback">{{ error.$message }}</div>
                      </div>
                  </div>
                  <div class="form-group">
                    <label for="" class="form__authformlabel">Password</label>
                    <input
                      :type="showPassword ? 'text':'password'"
                      class="form-control form__authformedit"
                      placeholder="****************"
                      v-model.trim="password"
                      :class="{ 'has-error': v$.password.$errors.length }"
                    />
                    <span class="__showpassword" style="color: gray; fontSize: 14px" @click.prevent="showPassword = !showPassword">
                      <i class="fa fa-eye-slash" v-if="!showPassword" aria-hidden="true"></i>
                      <i class="fa fa-eye" v-if="showPassword" aria-hidden="true"></i>
                    </span>
                    <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                      <div class="error-msg invalid-feedback">{{ error.$message }}</div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-7">
                      <label class="check">
                        Remember me
                        <input type="checkbox" name="is_name" id="check" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-5">
                      <router-link to="/forgot-password" class="form__authformfg">Forgot Password?</router-link>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-block form__authformbutton"
                    style="display: flex; justifyContent: center; alignItem: center"
                  >
                    Login <Loader :shouldShow="shouldShowLoader" />
                  </button>
                </form>

                <p class="authentication__log text-center">
                  New to Boookme?
                  <router-link to="/register" class="__loglink">
                    Create a new account here
                  </router-link>
                </p>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-2"></div>
          </div>
        </div>
        <div class="__spacet7p"></div>
      </div>
    </section>
    <topFooter />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';

import topHeader from "../components/topHeader.vue";
import topFooter from "../components/topFooter";
import Loader from "../components/loader";
import { errorHandler } from "../../utils/helpers";

export default {
  name: "Login",
  components: {
    topHeader,
    Loader,
    topFooter,
  },
  data() {
    return {
      email: "",
      password: "",
      shouldShowLoader: false,
      showPassword: false
    };
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Email field is required', required),
        email: helpers.withMessage('Please provide a valid email address', email),
      },
      password: {
        required: helpers.withMessage('Password field is required', required),
        minLength: helpers.withMessage('Password must have a minimum length of 6 digits', minLength(6)),
      },
    }
  },
  computed: mapGetters([
    "userToken"
  ]),
  methods: {
    ...mapActions([
      "loginUser"
    ]),
    async handleUserLogin() {
      this.v$.$touch();
      try {
        if (this.v$.$error) return;

        this.shouldShowLoader = true;
        const result = await this.loginUser({
          email: this.email,
          password: this.password,
        });

        if (result.errors && result.errors.length > 0) {
          this.shouldShowLoader = false;
          return this.$toast.error(`${result.errors[0]}`);
        }

        this.shouldShowLoader = false;

        this.$toast.success(`${result.message}`);

        localStorage.setItem(
          "book_me-user-token",
          JSON.stringify(`bearer ${result.data.token.token}`)
        );

        localStorage.setItem("book_me-logged-in-user-type", `${result.data.type}`);
        localStorage.setItem("book_me-logged-in-user", JSON.stringify(result.data));

        if(result.data.type === 'company') {
          localStorage.setItem("book_me-logged-in-user-slug", `${result.data.company.slug}`);
          localStorage.setItem("book_me-logged-in-real-user-slug", `${result.data.slug}`);
        } else {
          localStorage.setItem("book_me-logged-in-user-slug", `${result.data.slug}`);
        }

        this.input = {};

        if (result.data.avatar && result.data.type === 'talent') {
          this.$router.push(`/${result.data.slug}`);
        } else if (result.data.avatar && result.data.type === 'company'){
          this.$router.push(`/${result.data.slug}/company`);
        }

        if (!result.data.avatar && result.data.type === 'talent') {
          this.$router.push(`/${result.data.slug}/profile-edit`);
        } 

        if (!result.data.avatar && result.data.type !== 'talent') {
          this.$router.push(`/${result.data.company.slug}/company-profile-edit`);
        } 

      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.shouldShowLoader = false;
      }
    },
  },
};
</script>

<style lang="scss">
</style>
